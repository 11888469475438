import React from "react";
import Typography from "@material-ui/core/Typography";

const Success = () => {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Grazie!
      </Typography>
      <Typography variant="subtitle1">
        La richiesta di ammisibilità al servizio di chain2 è stata presa in
        carico.
      </Typography>
      <Typography variant="subtitle1">
        Riceverai una mail di conferma all’indirizzo da te indicato.
      </Typography>
      <Typography variant="subtitle1">
        Nel caso di problemi è consigliato controllare nella cartella SPAM o
        possibile contattare l’assistenza tecnica al numero 0732 633528*.
      </Typography>
      <Typography variant="subtitle2">
        * I costi della chiamata da rete fissa e mobile dipendono dalle
        condizioni contrattuali con il proprio gestore senza oneri aggiuntivi.
      </Typography>
    </React.Fragment>
  );
};

export default Success;
