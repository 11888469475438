import React, { useState, useReducer } from "react";
import { RequestReducer, DefaultRequest } from "./reducers/RequestReducer";
import {
  createAristonAdmissibilityRequest,
  createAdmissibilityRequest,
} from "../graphql/mutations";
import logo from "../assets/LogoAriston.jpg";
import bolletta from "../assets/pod.png";
import { API } from "aws-amplify";
import "./admissibility.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
  CardContent,
  TextField,
} from "@material-ui/core";
import Privacy from "./components/privacy";

import { Success, Failure } from "./components";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mac-italia.com/">
        MAC SRL
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  // title: {
  //   marginBottom: 16,
  //   fontSize: "16px",
  //   fontWeight: "700",
  //   lineHeight: "19.49px",
  // },
  accordion: {
    padding: "0px",
    marginLeft: "8px",
    // Aligm text center
    textAlign: "center",
  },
  top: {
    paddingTop: "0px",
    paddingBottom: "20px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    fontWeight: 700,
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  // root: {
  //   maxWidth: 345,
  // },
  media: {
    height: 200,
    objectFit: "cover",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  buttonPrincipal: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#B12431",
    color: "white",
    height: "50px",
    width: "200px",
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: "#B12431",
    borderColor: "#B12431",
    border: "1px solid",
    height: "50px",
    width: "200px",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
  },
}));

function validContent(request) {
  return (
    request.firstName != null &&
    request.firstName !== "" &&
    request.lastName != null &&
    request.lastName !== "" &&
    request.email != null &&
    request.email !== "" &&
    validatePOD(request.pod) &&
    validateFiscalCode(request.fiscalCode) &&
    validateEmail(request.email)
  );
}

function validateFiscalCode(fiscalCode) {
  const newRe =
    /^([A-Za-z]{6}[0-9LMNPQRSTUVlmnpqrstuv]{2}[a-hjkmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/;
  // const re =
  //   /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
  // re.test(fiscalCode)
  //   ? console.log("Fiscal Code is valid")
  //   : console.log("Fiscal Code is not valid");
  return newRe.test(fiscalCode);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Regex per validare il POD

function validatePOD(pod) {
  const re = /^IT\d{3}E\d{8,10}$/i;
  // const re = /^IT[0-9]{3}E\d{8,9}$/i;
  return re.test(pod);
}

function AdmissibilityForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [request, setRequest] = useReducer(RequestReducer, DefaultRequest);

  const handleChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const inputAriston = {
        pod: request.pod.toUpperCase(),
        parentID: request.parentID,
        fiscalCode: request.fiscalCode.toUpperCase(),
        email: request.email,
        firstName: request.firstName,
        lastName: request.lastName,
        status: "PENDING",
        group: "Ariston",
      };
      console.log("INPUT ARISTON", inputAriston);
      const _aristonReq = await API.graphql({
        query: createAristonAdmissibilityRequest,
        variables: {
          input: inputAriston,
        },
        authMode: "AWS_IAM",
      });
      console.log(_aristonReq);

      const portalInput = {
        pod: request.pod.toUpperCase(),
        parentType: "AristonAdmissibilityRequest",
        parentID: _aristonReq.data.createAristonAdmissibilityRequest.id,
        fiscalCode: request.fiscalCode.toUpperCase(),
        status: "PENDING",
        group: "Ariston",
      };
      console.log("PORTAL INPUT", portalInput);
      const portalResponse = await API.graphql({
        query: createAdmissibilityRequest,
        variables: {
          input: portalInput,
        },
        authMode: "AWS_IAM",
      });
      console.log(portalResponse);
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setSuccess(false);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <form>
            <div className="logo">
              <img src={logo} alt="Ariston" height={"auto"} width={200} />
            </div>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              className={classes.title}
            >
              VERIFICA LA COMPATIBILITÀ DEL TUO CONTATORE
            </Typography>
            {loading === true && success === null && (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            )}
            {loading === false && success === false && <Failure />}
            {loading === false && success === true && <Success />}
            {loading === false && success === null && (
              <>
                <Typography variant="body2">
                  Compila i seguenti campi per verificare che il tuo contatore
                  Smart Meter sia dotato di tecnologia Chain2 così da poter
                  sfruttare tutte le innovative funzionalità di Home+. Riceverai
                  un’email con l’esito della tua richiesta.{" "}
                </Typography>

                <Typography gutterBottom className={classes.title}>
                  DATI INTESTATARIO FORNITURA
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      variant="outlined"
                      value={request.firstName}
                      label="Nome"
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      autoComplete="given-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      value={request.lastName}
                      variant="outlined"
                      label="Cognome"
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="fiscalCode"
                      name="fiscalCode"
                      variant="outlined"
                      error={
                        validateFiscalCode(request.fiscalCode) === false &&
                        request.fiscalCode !== ""
                      }
                      helperText={
                        validateFiscalCode(request.fiscalCode.toUpperCase()) ||
                        request.fiscalCode === ""
                          ? ""
                          : "Codice fiscale non valido"
                      }
                      value={request.fiscalCode}
                      label="Codice Fiscale"
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      autoComplete="fiscal-code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      type="email"
                      id="email"
                      name="email"
                      error={
                        validateEmail(request.email) === false &&
                        request.email !== ""
                      }
                      helperText={
                        validateEmail(request.email) || request.email === ""
                          ? ""
                          : "Email non valida"
                      }
                      variant="outlined"
                      value={request.email}
                      label="E-mail"
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      type="pod"
                      id="pod"
                      name="pod"
                      variant="outlined"
                      error={
                        validatePOD(request.pod) === false && request.pod !== ""
                      }
                      helperText={
                        validatePOD(request.pod) || request.pod === ""
                          ? ""
                          : "Codice POD non valido"
                      }
                      value={request.pod}
                      label="Codice POD"
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      autoComplete="pod"
                    />
                  </Grid>
                  <div className="logo">
                    <CardActionArea>
                      <div className="logo">
                        <img src={bolletta} alt="Ariston" />
                      </div>
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Individuabile nella sezione “dati fornitura” del
                          frontespizio della bolletta
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </div>
                </Grid>
                <Accordion>
                  <AccordionSummary
                    className={classes.accordion}
                    id="panel-header"
                    aria-controls="panel-content"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <strong>
                      INFORMATIVA RELATIVA AL TRATTAMENTO DEI DATI PERSONALI
                      DEGLI UTENTI AI SENSI DEL REGOLAMENTO UE 2016/679 (“GDPR”)
                    </strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Privacy />
                  </AccordionDetails>
                </Accordion>
                <Typography gutterBottom className={classes.title}>
                  DICHIARAZIONE DI CONSENSO
                </Typography>
                <Typography variant="body2" gutterBottom align="center">
                  Il sottoscritto {request.firstName + " " + request.lastName},
                  esaminata l’informativa privacy sopra riportata, consapevole
                  che il mio consenso è facoltativo e può essere revocato in
                  qualsiasi momento, esprimo il mio consenso al trattamento dei
                  dati da parte di Ariston S.p.A. in qualità di Titolare del
                  Trattamento, cliccando su "Invia richiesta" si accettano le
                  condizioni sopracitate.
                </Typography>
                {/* <RadioGroup
                  row
                  aria-label="gender"
                  name="privacy"
                  className={classes.radio}
                  value={request.privacy || "unknown"}
                  onChange={(e) => handleChange(e)}
                >
                  <Grid item xs={6} align="center">
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Acconsento"
                    />
                  </Grid>
                  <Grid item xs={6} align="center">
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Non acconsento"
                    />
                  </Grid>
                </RadioGroup> */}
                <div className={classes.buttonWrapper}>
                  <Button
                    disabled={!validContent(request)}
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.buttonPrincipal}
                  >
                    Invia richiesta
                  </Button>
                </div>
              </>
            )}
          </form>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

export default AdmissibilityForm;
