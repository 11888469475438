import React from "react";
import SubjectIcon from "@material-ui/icons/Subject";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneIcon from "@material-ui/icons/Done";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InputIcon from "@material-ui/icons/Input";
import LaunchIcon from "@material-ui/icons/Launch";
import MapIcon from "@material-ui/icons/Map";
import WcIcon from "@material-ui/icons/Wc";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const preventDefault = (event) => event.preventDefault();
const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.header}>
        {/* TITOLARE DEL TRATTAMENTO */}
        <Grid
          container
          xs={12}
          sm={12}
          md={7}
          lg={7}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
          >
            <Grid container flex={1} display="flex" alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                border={1}
                bgcolor="primary.main"
                padding={1}
                style={{ backgroundColor: "#d1dbf0" }}
              >
                <PersonIcon style={{ fontSize: 60 }} />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography variant="body2">
                  <strong>TITOLARE DEL TRATTAMENTO</strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={5}
          lg={5}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">
              <strong>Ariston S.p.A.</strong>
            </Typography>
            <Typography variant="body2">Viale Aristide Merloni, 45</Typography>
            <Typography variant="body2">60044 Fabriano (AN) Italy</Typography>
            <Typography variant="body2">T. +39 0732 6011</Typography>
            <Typography variant="body2">
              E-mail: ariston@pec.ariston.com
            </Typography>
            <Typography variant="body2">CF e P.IVA: IT01026940427</Typography>
            <Typography variant="body2">(“Società”)</Typography>
          </Box>
        </Grid>
        {/* DATA PROTECTION OFFICER */}
        <Grid
          container
          xs={12}
          sm={12}
          md={7}
          lg={7}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
          >
            <Grid container>
              <Grid item xs={4} sm={4} alignContent="center">
                <VerifiedUserIcon style={{ fontSize: 50 }} />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography variant="button" align="center" gutterBottom>
                  <strong>Data Protection Officer (DPO)</strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={5}
          lg={5}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">
              <strong>Partner4Innovation S.r.l.</strong>
            </Typography>
            <Typography variant="body2">
              Via Copernico, n.38, 20125 Milano (MI), Italia
            </Typography>
            <Typography variant="body2">T. +39 0292852779</Typography>
            <Typography variant="body2">E-mail: dpo-p4i@pec.it</Typography>
          </Box>
        </Grid>

        {/* PERSONAL DATA PROCESSED */}
        <Grid container xs={0} sm={0} md={2}>
          <Box
            border={1}
            bgcolor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <AssignmentIcon style={{ fontSize: 50 }} />
          </Box>
        </Grid>
        <Grid container xs={12} sm={12} md={10} lg={10}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                <strong>PERSONAL DATA PROCESSED</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Nome, Cognome, Email, POD (Point of Delivery, codice
                identificativo del contatore).
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* FINALITÀ DEL TRATTAMENTO */}
        <Grid
          container
          xs={4}
          sm={4}
          md={4}
          lg={4}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box
            border={1}
            container
            style={{ backgroundColor: "#d1dbf0" }}
            display={{ xs: "none", sm: "none", md: "block" }}
            alignContent={"center"}
          >
            <CheckCircleOutlineIcon style={{ fontSize: 55 }} />
          </Box>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            alignContent={"center"}
          >
            <Typography variant="body2" align="center">
              FINALITÀ DEL TRATTAMENTO
            </Typography>
          </Box>
        </Grid>

        {/* BASE GIURIDICA DEL TRATTAMENTO */}
        <Grid
          container
          xs={4}
          sm={4}
          md={4}
          lg={4}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box
            border={1}
            container
            style={{ backgroundColor: "#d1dbf0" }}
            display={{ xs: "none", sm: "none", md: "block" }}
            alignContent={"center"}
          >
            <SubjectIcon style={{ fontSize: 55 }} />
          </Box>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            alignContent={"center"}
          >
            <Typography variant="body2" align="center">
              BASE GIURIDICA DEL TRATTAMENTO
            </Typography>
          </Box>
        </Grid>

        {/* PERIODO DI CONSERVAZIONE DEI DATI */}

        <Grid
          container
          xs={4}
          sm={4}
          md={4}
          lg={4}
          style={{ backgroundColor: "#d1dbf0" }}
        >
          <Box
            border={1}
            container
            style={{ backgroundColor: "#d1dbf0" }}
            display={{ xs: "none", sm: "none", md: "block" }}
            alignContent={"center"}
          >
            <DoneIcon style={{ fontSize: 55 }} />
          </Box>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            alignContent={"center"}
          >
            <Typography variant="body2" align="center">
              PERIODO DI CONSERVAZIONE DEI DATI
            </Typography>
          </Box>
        </Grid>

        {/* TABLE CONTENT */}
        {/* ROW 1 */}

        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              I dati forniti compilando il modulo saranno trattati dalla Società
              per la verifica del servizio Chain 2 e di tutte le attività
              connesse da parte della Società stessa e/o sui suoi
              prodotti/servizi.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Esecuzione di un contratto, ai sensi dell'art. 6, (1), b) del
              GDPR.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Per tutta la durata del rapporto contrattuale e per i successivi
              10 anni.
            </Typography>
          </Box>
        </Grid>

        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Adempiere ad eventuali obblighi previsti dalla normativa nazionale
              e sovrannazionale applicabile.
            </Typography>
          </Box>
        </Grid>

        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Necessità di adempiere agli obblighi di legge, ai sensi dell'art.
              6, (1), c) del GDPR.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Termine previsto dalla legge (10 anni per gli obblighi
              amministrativi e contabili).{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Gestione della sicurezza e delle risorse informatiche (back-up,
              restore, accessi logici all’eventuale account online).
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Esecuzione di un contratto, ai sensi dell'art. 6, (1), b) del
              GDPR.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Per tutta la durata del rapporto contrattuale e per i successivi
              10 anni.{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Se necessario per stabilire, esercitare e/o difendere un diritto
              del Titolare del Trattamento in procedimenti giudiziari.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Legittimo interesse del Titolare del Trattamento (tutela
              giurisdizionale), ai sensi dell’art. 6, (1), f) del GDPR.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} sm={4} container>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              In caso di contenzioso giudiziale, per tutta la durata dello
              stesso, fino alla scadenza dei termini di esperibilità delle
              azioni di impugnazione.{" "}
            </Typography>
          </Box>
        </Grid>

        <Grid container xs={12} sm={12}>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Scaduti i suddetti periodi di conservazione, i dati saranno
                distrutti, cancellati o anonimizzati compatibilmente con le
                procedure tecniche per la cancellazione e il backup con i
                requisiti di accountability del Titolare del Trattamento.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <InputIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                CONFERIMENTO DEI DATI PERSONALI
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Il conferimento dei dati contrassegnati da un (*) all’interno
                del form di iscrizione è necessario anche per garantire la
                fornitura dei servizi richiesti.{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <LaunchIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">DESTINATARI DEI DATI</Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                I dati potranno essere comunicati a altre società del Gruppo
                Ariston e a soggetti esterni operanti in qualità di autonomi
                titolari del trattamento, quali ad esempio, organi di controllo
                o autorità, nonché a qualsiasi soggetto pubblico o privato
                legittimato a richiedere tali dati.
              </Typography>
              <Typography variant="body2">
                I dati potranno essere comunicati o trattati, per conto della
                Società, da soggetti esterni nominati Responsabili del
                Trattamento, ai quali verranno fornite adeguate istruzioni
                operative. Tali soggetti sono sostanzialmente inclusi nelle
                seguenti categorie:{" "}
              </Typography>
              <ol type="a">
                <li>altre società del Gruppo Ariston;</li>
                <li>società che offrono servizi di posta elettronica;</li>
                <li>
                  società che offrono servizi di manutenzione di siti web;
                </li>
                <li>
                  società che offrono supporto nella realizzazione di studi di
                  mercato;
                </li>
                <li>società e agenzie nel campo di eventi e fiere;</li>
                <li>
                  società che offrono servizi strumentali al perseguimento delle
                  finalità indicate nella presente informativa (agenzie di
                  comunicazione, fornitori IT, spedizionieri, Softwarehouse &
                  system integrator, società di e-commerce, società di
                  consulenza, ecc.);
                </li>
                <li>
                  aziende che offrono servizi di call center e customer care;
                </li>
                <li>società di information provider;</li>
              </ol>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <WcIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                SOGGETTI AUTORIZZATI AL TRATTAMENTO
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                I dati potranno essere trattati dai dipendenti delle funzioni
                aziendali deputate al perseguimento delle finalità sopra
                indicate, che sono stati espressamente autorizzati al
                trattamento e che hanno ricevuto adeguate istruzioni operative.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <MapIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                TRASFERIMENTO DEI DATI PERSONALI IN PAESI NON APPARTENENTI
                ALL’UNIONE EUROPEA
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Qualora i dati personali vengano trasferiti in paesi non
                appartenenti all’Unione Europea (UE) o allo Spazio Economico
                Europeo (SEE) non ritenuti adeguati dalla Commissione Europea,
                saranno utilizzati i meccanismi di trasferimento di cui all’art.
                46 del GDPR (come le clausole contrattuali standard), valutando
                se le “misure aggiuntive” siano appropriate a garantire un
                livello di protezione sostanzialmente equivalente a quello
                richiesto dal diritto dell’EU.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <AccountBalanceIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                DIRITTI DELL'INTERESSATO - RECLAMO ALL’AUTORITÀ DI CONTROLLO
              </Typography>
            </Box>
          </Grid>
          <Grid container>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati possono contattare il Titolare del Trattamento
                inviando una mail a Ariston S.p.A., Viale Aristide Merloni 45,
                60044 Fabriano (AN), Italia,{" "}
                <Link
                  href="mailto:privacy.ariston@ariston.com"
                  onClick={preventDefault}
                >
                  privacy.ariston@ariston.com
                </Link>
                al fine di richiedere l’accesso ai dati personali che li
                riguardato, la rettifica di dati inesatti, l’integrazione di
                dati incompleti, la cancellazione dei dati personali, la
                limitazione del trattamento dei dati nei casi stabiliti
                dall’art.18 del GDPR, ove applicabile, nonché il diritto di
                opporsi in qualsiasi momento, in tutto o in parte, per motivi
                relativi alla loro particolare situazione, al trattamento per
                legittimo interesse del Titolare del Trattamento.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Inoltre, nel caso in cui il trattamento sia basato sul consenso
                o su un contratto e sia effettuato tramite strumenti
                automatizzati, gli interessati hanno il diritto di ricevere i
                dati personali in un formato strutturato, di uso comune e
                leggibile da dispositivo automatico, nonché di trasmettere i
                dati ad un altro titolare del trattamento senza impedimenti.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati hanno il diritto di presentare un reclamo
                all’Autorità Garante competente nello stato membro in cui
                risiedono o lavorano abitualmente o nello stato membro in cui ha
                avuto luogo la presunta violazione.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati hanno il diritto di revocare il consenso
                prestato in qualsiasi momento in relazione ai dati trattati per
                finalità di marketing, nonché di opporsi al trattamento dei dati
                per tale finalità. Resta ferma la possibilità dell’interessato
                di esprimere la propria preferenza ad essere contattati per tali
                finalità tramite modalità tradizionali e di opporsi soltanto
                alla ricezione di comunicazioni attraverso modalità
                automatizzate.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            bgcolor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <VisibilityIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                PRESA VISIONE DEL’INFORMATIVA
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Dichiaro di aver letto l’informativa fornita ai sensi dell’art.
                13 del Regolamento Europeo 2016/679
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
